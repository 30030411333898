@import './assets/fonts/fonts.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-font-primary{
  font-family: 'Montserrat Alt 1';
  text-transform: uppercase;
}

.navbar-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #0A141D;
  z-index: 1000;
}

.navbar-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #0A141D;
  z-index: 1000;
  display: flex;
  justify-content: space-around;
}